
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ContainerWrap {
  padding-top: 32px;
  padding-bottom: 32px;
}

.Container {
  position: relative;
  padding-top: 66.67%;
  border-radius: 16px;
  overflow: hidden;
}

.IFrame {
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
