
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.TextControl {
  display: block;
  width: 100%;
  font-family: inherit;
  background-color: $color-white;
  transition: all $duration-standard $easing-standard;
  padding: spacing(1.5) - 1 spacing(1.5);
  color: inherit;
  line-height: spacing(3);
  // border: 2px solid $color-grey80;
  border: 0;
  background-clip: padding-box;
  border-radius: spacing(1);
  caret-color: $color-primary;
  box-shadow: 0 0 0 1px inset $color-black, 0 0 0 1px $color-black;
  font-size: 16px;

  @include mq($breakpoint-tablet) {
    font-size: 18px;
  }

  &:invalid {
    box-shadow: 0 0 0 1px inset $color-grey80, 0 0 0 1px $color-grey80;
  }

  // &::placeholder {}

  &:hover,
  &:focus,
  &:active {
    transition-duration: $duration-shortest;
  }

  &:hover {
    box-shadow: 0 0 0 2px inset $color-black, 0 0 0 2px $color-black;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px inset $color-green, 0 0 0 2px $color-green;
  }

  // &[type='date'] {
  //   appearance: none;
  //   height: spacing(6);
  // }
}

.multiLine {
  height: auto;
  resize: vertical;
}

@each $key, $value in $color-status-list {
  .#{$key} {
    box-shadow: 0 0 0 1px inset $value, 0 0 0 1px $value !important;

    &:active,
    &:focus {
      box-shadow: 0 0 0 2px inset $value, 0 0 0 2px $value !important;
    }
  }
}

.disabled {
  pointer-events: none;
  color: $color-grey80 !important;
  border-color: $color-grey80 !important;
  box-shadow: 0 0 0 1px inset $color-grey80, 0 0 0 1px $color-grey80 !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 1px inset $color-grey80, 0 0 0 1px $color-grey80 !important;
  }
}
