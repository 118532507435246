
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FilterPanel {
  legend {
    margin-left: auto;
    margin-right: auto;
  }
}

.FilterPanelOptions {
  text-align: left;

  > * {
    padding: spacing(1);

    @include mq($breakpoint-tablet) {
      display: inline-block;
      width: 50%;
    }
    @include mq($breakpoint-desktop) {
      width: 33%;
    }
  }
}

.FilterPanelOptionsCentered {
  @include mq($breakpoint-desktop) {
    > * > * {
      margin: 0 auto;
    }
  }
}
