
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
$bevel-size: 32px;

.ShapedContainer {
  background-color: rgba($color-black, 0.5);
}

.rounded {
  border-radius: $bevel-size;
}

.bevelled {
  clip-path: polygon(
    #{$bevel-size} 0,
    calc(100% - #{$bevel-size}) 0,
    100% #{$bevel-size},
    100% calc(100% - #{$bevel-size}),
    calc(100% - #{$bevel-size}) 100%,
    #{$bevel-size} 100%,
    0 calc(100% - #{$bevel-size}),
    0 #{$bevel-size}
  );
}
