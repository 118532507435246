
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.CheckControlText {
  font-size: 16px;

  @include mq($breakpoint-tablet) {
    font-size: 18px;
  }
}
