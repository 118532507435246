
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Fieldset {
  text-align: center;
  border: 0;
}

.FieldsetLegend {
  font-weight: bold;
  margin-bottom: spacing(2);
}

.FieldsetRows {
  @include mq($breakpoint-tablet) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > * {
      flex-basis: 50%;
    }
  }
}

.trailingGap {
  margin-bottom: spacing(6);
}

.font-large {
  font-size: 32px;
}
