
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Product {
  position: relative;
  text-align: center;
  color: inherit;
  text-decoration: none;
}

.ProductImage {
  background-color: $color-grey85;
  position: relative;
  transition: all $duration-short $easing-bounce;
  @include corners($crop: false);

  .ProductImageInner {
    @include corners;
  }

  img {
    @include corners;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: box-shadow $duration-short $easing-bounce;
    box-shadow: 0 0 0 0 inset $color-green, 0 0 0 0 $color-green;
    @supports (--css: variables) {
      box-shadow: 0 0 0 0 inset var(--color-section-accent, $color-green),
        0 0 0 0 var(--color-section-accent, $color-green);
    }
    @include corners($crop: false);
  }

  .Product:hover &,
  .Product:focus &,
  .Product:focus-within & {
    outline: 0;

    &::after {
      box-shadow: 0 0 0 spacing(0.25) inset $color-green,
        0 0 0 spacing(0.25) $color-green;
      @supports (--css: variables) {
        box-shadow: 0 0 0 (0.25) inset var(--color-section-accent, $color-green),
          0 0 0 spacing(0.25) var(--color-section-accent, $color-green);
      }
      @include mq($breakpoint-desktop) {
        box-shadow: 0 0 0 spacing(0.5) inset $color-green,
          0 0 0 spacing(0.5) $color-green;
        @supports (--css: variables) {
          box-shadow: 0 0 0 spacing(0.5) inset
              var(--color-section-accent, $color-green),
            0 0 0 spacing(0.5) var(--color-section-accent, $color-green);
        }
      }
    }
  }

  .Product:hover & {
    transform: scale(1.03) rotate(2deg);
  }
  .Product:active & {
    transform: scale(0.99) rotate(2deg);
    transition-duration: 0.1s;
  }

  .Product:nth-child(5n + 2):hover & {
    transform: scale(1.03) rotate(-1deg);
  }
  .Product:nth-child(5n + 2):active & {
    transform: scale(0.99) rotate(-1deg);
  }

  .Product:nth-child(5n + 3):hover & {
    transform: scale(1.02) rotate(1deg);
  }
  .Product:nth-child(5n + 3):active & {
    transform: scale(0.99) rotate(1deg);
  }

  .Product:nth-child(5n + 4):hover & {
    transform: scale(1.03) rotate(-2deg);
  }
  .Product:nth-child(5n + 4):active & {
    transform: scale(0.99) rotate(-2deg);
  }

  .Product:nth-child(5n + 5):hover & {
    transform: scale(1.07) rotate(-2deg);
  }
  .Product:nth-child(5n + 5):active & {
    transform: scale(0.99) rotate(-2deg);
  }
}

.ProductTitleWrapper {
  margin-top: spacing(2);
}

.ProductDescription {
  padding: spacing(2);
}
