
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.PanelAlt {
  background-color: $color-purple;
  color: $color-white;
  @include panel-spacing;
}

.corners {
  @include corners;
}

.grow {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
}
