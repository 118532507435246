
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FieldAssistance {
  margin-top: spacing(0.5);
  color: $color-grey40;
  font-size: 14px;
  @include mq($breakpoint-desktop) {
    font-size: 16px;
  }

  a {
    color: currentColor;
  }
}
