
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Notification {
  color: $color-grey25;
  padding: spacing(2) spacing(2);
  background-color: tint($color-grey75, 80%);
  display: flex;
  @include corners;
}

.NotificationIcon {
  margin-right: spacing(1);

  @include mq($breakpoint-tablet) {
    margin-right: spacing(2);
  }
}

.NotificationContent {
  flex: 1 1 auto;
  min-width: 0;

  a {
    color: inherit;
  }
}

.NotificationDismiss {
  margin-left: spacing(1);

  @include mq($breakpoint-tablet) {
    margin-left: spacing(2);
  }
}

.shadow {
  box-shadow: shadow(4);
}

@each $key, $value in $color-status-list {
  .#{$key} {
    background-color: tint($value, 80%);
    color: shade($value, 75%);

    svg {
      fill: $value !important;
    }
  }
}
