
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '../lib/GridGutter';
@import '../lib/GridReverse';

$gutters: (
  'half': spacing(1),
  'default': spacing(2),
  'double': spacing(4),
  'triple': spacing(6)
);

// .GridWrapper {}

@include grid-gutters($gutters);

@include grid-reverse();

@include mq($breakpoint-desktop) {
  @include grid-reverse(d);
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.centre {
  justify-content: center;
}

// :export {
//   /* stylelint-disable-next-line property-no-unknown */
//   gutters: map-keys($gutters);
// }
