
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Hero {
  position: relative;

  @include mq($breakpoint-tablet) {
    margin-bottom: spacing(-2);
  }

  @include mq($breakpoint-desktopMedium) {
    margin-bottom: spacing(-6);
  }
}

.HeroOverlap {
  margin-bottom: spacing(-5);

  @include mq($breakpoint-tablet) {
    margin-bottom: spacing(-10);
  }

  @include mq($breakpoint-tabletLandscape) {
    max-height: calc(100vh - 138px);
    margin-bottom: spacing(-16);
  }

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(-23);
  }

  @include mq($breakpoint-desktopMedium) {
    margin-bottom: spacing(-31);
  }
}

.HeroBackgroundFade {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  height: spacing(5);
  transform: translateY(50%);
  pointer-events: none;
  background-size: 100% 50%;
  background-position: 50% 0, 50% 100%;
  background-repeat: no-repeat;
  @include mq($breakpoint-tablet) {
    height: spacing(5) * 2;
  }
  @include mq($breakpoint-desktopMedium) {
    height: spacing(8) * 2;
  }
}

.HeroBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.HeroContent {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: $breakpoint-desktopMedium;
  margin: auto;
  padding: spacing(2);

  @include mq($breakpoint-tablet) {
    padding: spacing(4);
  }
  // background: rgba(lime, 0.5); // Debug
}

.HeroContentInner {
  position: relative;
}

.HeroVideo {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.HeroVideoInner {
  position: relative;
}

.manualHeight {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;

  @include mq($breakpoint-tablet) {
    min-height: 450px;
  }
  @include mq($breakpoint-desktopMedium) {
    min-height: 500px;
  }

  .HeroContent {
    position: absolute;
    width: 100%;
    text-shadow: 0 1px 3px rgba($color-black, 0.5),
      0 0 1px rgba($color-black, 0.5);
    font-weight: bold;
  }

  .HeroBackground {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
