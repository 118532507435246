
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ImageFrame {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-grey95;

  &.noBackground {
    background: unset;
    position: relative;
  }

  > img {
    max-width: 100%;
    max-height: 100%;
  }
}
