
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .ProductGrid {}

.ProductGridInner {
  display: flex;
  flex-wrap: wrap;
  margin: spacing(-2);
  justify-content: space-around;

  > * {
    flex: 0 1 350px;
    margin: spacing(2);
  }

  // Using Grid to allow for better `gap`.

  @supports (display: grid) {
    display: grid;
    grid-gap: spacing(2);
    margin: 0;
    grid-template-columns: repeat(1, 1fr);

    @include mq($breakpoint-tablet) {
      grid-gap: spacing(4);
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq($breakpoint-desktop) {
      grid-gap: spacing(6);
      grid-template-columns: repeat(3, 1fr);
    }

    @include mq($breakpoint-desktopMedium) {
      grid-gap: spacing(8);
    }

    > * {
      margin: 0;
    }
  }
}
