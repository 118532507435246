
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .WidgetWrapper {}

@include mq($max: $breakpoint-tablet - 1) {
  .hidden-mobile {
    display: none;
  }
}

@include mq($breakpoint-tablet, $max: $breakpoint-desktop - 1) {
  .hidden-tablet {
    display: none;
  }
}

@include mq($breakpoint-desktop) {
  .hidden-desktop {
    display: none;
  }
}
