
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .CaptionedMedia {}

// .CaptionedMediaBody {}

.CaptionedMediaCaption {
  padding: spacing(1) 0;
}
