
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FieldQuestion {
  margin-bottom: spacing(0.5);
  display: inline-block;
  font-size: 16px;
  font-weight: bold;

  @include mq($breakpoint-tablet) {
    font-size: 18px;
  }
}

.disabled {
  color: $color-grey80;
}
