
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Table {
  caption,
  th {
    text-align: start;
    font-size: 18px;
    padding-left: 10px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;

    thead {
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      display: block;
      margin-bottom: 0.625em;
    }

    td {
      display: block;
      font-size: 0.8em;
      text-align: right;
    }

    td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }

    td:last-child {
      border-bottom: 0;
    }
  }
}
