
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FormBuilder {
  padding-bottom: 50px;
}

.WidthFull {
  width: 100%;
}

.WidthHalf {
  width: 50%;
  display: inline-block;
}

.WidthQuarter {
  width: 25%;
  display: inline-block;
}
