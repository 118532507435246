
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ProductPromo {
  list-style: none;
}

.ProductPromoContent {
  text-align: center;
  a {
    text-decoration: none;
    color: inherit;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.ProductPromoActions {
  a {
    width: 100%;
    margin-top: 10px;

    @include mq($breakpoint-tablet) {
      width: auto;
    }
  }

  > a:first-child {
    margin-right: 10px;
  }
}
