
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Faq {
  display: block;
  list-style: none;
  margin: 0;
  padding-top: spacing(1);
  padding-bottom: spacing(1);
  position: relative;
  border-top: 1px solid $color-grey80;
  border-bottom: 1px solid $color-grey80;

  @include mq($breakpoint-tablet) {
    padding-top: spacing(2);
    padding-bottom: spacing(2);
  }

  & + & {
    border-top: 0;
  }
}

.FaqQuestion {
  width: 100%;
  text-align: left;
  vertical-align: bottom;

  &:focus {
    outline: 0;
  }
}

.FaqIndicator {
  transition: transform 0.3s ease-out;

  .expanded & {
    transform: rotate(0.5turn);
  }
}

.FaqAnswer {
  overflow: hidden;
}

.FaqAnswerInner {
  padding-top: spacing(1);
}
