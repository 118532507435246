
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SocialShare {
  background-color: $color-green;
  color: $color-white;
  font-weight: 700;
  width: spacing(8);
  padding: spacing(1);
  text-transform: uppercase;
  border-top-left-radius: spacing(4);
  border-bottom-left-radius: spacing(4);
  text-align: center;
  z-index: 2;
  position: fixed;
  right: 0;
  bottom: 15%;

  @include mq($breakpoint-tablet) {
    bottom: auto;
    top: 25%;
  }
}

.SocialShareHeading {
  font-size: spacing(1.5);
}

.SocialShareList {
  list-style: none;
  margin-top: spacing(0.5);
}

.SocialShareItem {
  display: inline-block;

  & + & {
    margin-top: spacing(1);
  }
}
