$panel-spacing-mobile: spacing(2);
$panel-spacing-tablet: spacing(4);
$panel-spacing-desktop: spacing(6);
$panel-spacing-desktopMedium: spacing(8);

@mixin panel-spacing() {
  padding-top: $panel-spacing-mobile;
  padding-bottom: $panel-spacing-mobile;

  @include mq($breakpoint-tablet) {
    padding-top: $panel-spacing-tablet;
    padding-bottom: $panel-spacing-tablet;
  }

  @include mq($breakpoint-desktop) {
    padding-top: $panel-spacing-desktop;
    padding-bottom: $panel-spacing-desktop;
  }

  @include mq($breakpoint-desktopMedium) {
    padding-top: $panel-spacing-desktopMedium;
    padding-bottom: $panel-spacing-desktopMedium;
  }
}

@mixin panel-spacing-reverse-top() {
  margin-top: $panel-spacing-mobile * -1;

  @include mq($breakpoint-tablet) {
    margin-top: $panel-spacing-tablet * -1;
  }

  @include mq($breakpoint-desktop) {
    margin-top: $panel-spacing-desktop * -1;
  }

  @include mq($breakpoint-desktopMedium) {
    margin-top: $panel-spacing-desktopMedium * -1;
  }
}

@mixin panel-spacing-reverse-bottom() {
  margin-bottom: $panel-spacing-mobile * -1;

  @include mq($breakpoint-tablet) {
    margin-bottom: $panel-spacing-tablet * -1;
  }

  @include mq($breakpoint-desktop) {
    margin-bottom: $panel-spacing-desktop * -1;
  }

  @include mq($breakpoint-desktopMedium) {
    margin-bottom: $panel-spacing-desktopMedium * -1;
  }
}
