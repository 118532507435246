
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.PaginationSummary {
  display: inline-block;
  padding: spacing(1) 0;
}

.PaginationSummaryButton {
  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
}

.PaginationSummaryText {
  display: inline-block;
  font-variant-numeric: tabular-nums;
  min-width: 3em;
  text-align: center;
  vertical-align: middle;
}
