
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.GettingStartedContent {
  legend {
    margin-left: auto;
    margin-right: auto;
  }
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.optionButton {
  height: 90px;
  font-size: 24px;
  line-height: 1em;
  align-items: center;
  display: inline-flex;
  justify-content: center;

  @include mq($max: $breakpoint-tablet) {
    font-size: 16px;
    height: 70px;
  }
}
