
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .SpacingReverser {}

:first-child > .main {
  @include main-spacing-reverse-top;
}

// :last-child > .main {
//   @include main-spacing-reverse-bottom;
// }

:first-child > .panel {
  @include panel-spacing-reverse-top;
}

:last-child > .panel {
  @include panel-spacing-reverse-bottom;
}
