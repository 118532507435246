
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.AuthorImage {
  border-radius: 100%;
  max-width: 55px;
  @include mq($breakpoint-tablet) {
    max-width: 100px;
    margin-top: 0;
  }
}
