
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.VideoPreview {
  position: relative;

  &:not(.alternateLayout) {
    @include mq($breakpoint-tabletLandscape) {
      &:hover .VideoPreviewOverlayContent,
      &:focus .VideoPreviewOverlayContent {
        opacity: 0.5;
      }
    }
  }
}

.VideoPreviewPlaceholder {
  background: $color-black;
  color: $color-white;
  @include corners;

  .alternateLayout & {
    box-shadow: 0 1px 5px 0 rgba($color-grey40, 0.75),
      0 2px 2px 0 rgba($color-grey40, 0.75),
      0 3px 1px -2px rgba($color-grey40, 0.75);
  }
}

.VideoPreviewBg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity $duration-long 0.2s $easing-standard;

  .active & {
    opacity: 0;
    pointer-events: none;
  }

  .alternateLayout &::before {
    content: none;
  }

  @include mq($breakpoint-tabletLandscape) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
}

.VideoPreviewAction {
  border: 0;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  @include mq($breakpoint-tabletLandscape) {
    padding-left: 35%;
  }

  @include mq($breakpoint-desktop) {
    padding-left: 0;
  }

  .active & {
    pointer-events: none;
  }
}

.VideoPreviewActionInner {
  height: spacing(9);
  width: spacing(9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-green;
  @supports (--css: variables) {
    background-color: var(--color-section-accent, $color-green);
  }

  @include mq($breakpoint-tabletLandscape) {
    height: spacing(11);
    width: spacing(11);
  }

  transition: transform $duration-standard $easing-bounce;
  z-index: 1;

  .VideoPreviewAction:hover &,
  .VideoPreviewAction:focus & {
    transform: scale(1.2) rotate(-5deg);
  }

  .VideoPreviewAction:active & {
    transform: scale(0.98) rotate(-5deg);
  }

  .active & {
    transition: all $duration-longest $easing-standard;
    transform: scale(0) rotate(270deg) !important;
    opacity: 0;
  }
}

.VideoPreviewOverlay {
  @include mq($breakpoint-tabletLandscape) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: opacity $duration-long 0.3s $easing-standard;

    .active & {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.VideoPreviewOverlayContent {
  margin-top: spacing(2);

  @include mq($breakpoint-tabletLandscape) {
    color: $color-white;
    margin-top: 0;
    position: relative;
    padding: spacing(4);
    max-width: spacing(48);
    transition: opacity $duration-long $easing-standard;
  }

  .alternateLayout & {
    @include mq($max: $breakpoint-tabletLandscape - 1) {
      padding-bottom: spacing(2);
    }
    @include mq($breakpoint-tabletLandscape) {
      color: $color-black;
      max-width: none;
    }
  }
}

.VideoPreviewOverlayDetails {
  .alternateLayout & {
    padding-top: 0;
  }
}

.VideoPreviewContent {
  transition: opacity $duration-long $easing-standard;
  opacity: 0;
  .active & {
    opacity: 1;
  }
}
