
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Banner {
  background-color: $color-purple;
  text-align: center;
  color: $color-white;
  padding: spacing(1) spacing(2);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: spacing(8);
  font-size: spacing(2);
  line-height: 1.3;

  @include mq($breakpoint-desktop) {
    min-height: spacing(9);
    font-size: spacing(2.5);
  }

  a {
    color: $color-white;
    text-decoration: underline;
  }
}
