
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.CustomCheckControl {
  display: inline-block;
  position: relative;
}

.CustomCheckControlControl {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.CustomCheckControlIndicator {
  position: relative;
  display: inline-block;
  width: spacing(4);
  height: spacing(4);
  background: $color-white;
  vertical-align: middle;
  box-shadow: inset 0 0 0 1px $color-black, 0 0 0 1px $color-black;
  border-radius: spacing(0.5);
  transition: all $duration-standard $easing-standard;

  .CustomCheckControlControl:hover:not([disabled]) ~ &,
  .CustomCheckControlControl:focus ~ & {
    box-shadow: inset 0 0 0 2px $color-black, 0 0 0 2px $color-black;
    transition-duration: $duration-shortest;
  }

  .CustomCheckControlControl:active ~ &,
  .CustomCheckControlControl:focus ~ & {
    box-shadow: inset 0 0 0 2px $color-green, 0 0 0 2px $color-green;
  }

  .CustomCheckControlControl:checked ~ & {
    box-shadow: inset 0 0 0 1px $color-green, 0 0 0 1px $color-green;
  }

  .CustomCheckControlControl:hover:not([disabled]):checked ~ &,
  .CustomCheckControlControl:checked:focus ~ & {
    box-shadow: inset 0 0 0 2px $color-green, 0 0 0 2px $color-green;
  }

  .CustomCheckControlControl:disabled ~ & {
    opacity: 0.6;
    border-color: $color-grey80;
    cursor: not-allowed;
  }

  &::after {
    position: absolute;
    content: '';
    opacity: 0;
    transform: scale(0);
    transition: opacity $duration-long $easing-standard,
      transform $duration-long cubic-bezier(0.6, 1.5, 0.8, 1.15);
  }

  .CustomCheckControlControl:checked ~ &::after {
    opacity: 1;
    transform: scale(1);
    transition-duration: $duration-shortest;
  }
}

//
// CHECKBOX
//

.checkbox {
  .CustomCheckControlIndicator::after {
    top: 50%;
    left: 50%;
    margin-left: -5px;
    margin-top: -11px;
    width: 9px;
    height: 18px;
    transform: scale(0) rotate(45deg);
    border-style: solid;
    border-color: $color-primary;
    border-width: 0 4px 4px 0;
  }

  .CustomCheckControlControl:checked ~ .CustomCheckControlIndicator::after {
    transform: scale(1) rotate(45deg);
  }
  .CustomCheckControlControl:disabled ~ .CustomCheckControlIndicator::after {
    border-color: $color-grey80;
  }
}

//
// RADIO
//

.radio {
  .CustomCheckControlIndicator {
    border-radius: 50%;
  }
  .CustomCheckControlIndicator::after {
    top: 50%;
    left: 50%;
    width: spacing(2.5);
    height: spacing(2.5);
    margin-left: spacing(-1.25);
    margin-top: spacing(-1.25);
    border-radius: 50%;
    background-color: $color-primary;
  }
  .CustomCheckControlControl:disabled .CustomCheckControlIndicator::after {
    background-color: $color-grey80;
  }
}

@each $key, $value in $color-status-list {
  .#{$key} {
    .CustomCheckControlIndicator {
      box-shadow: inset 0 0 0 1px $value, 0 0 0 1px $value !important;
    }
    .CustomCheckControlControl:hover:not([disabled])
      ~ .CustomCheckControlIndicator,
    .CustomCheckControlControl:focus ~ .CustomCheckControlIndicator {
      box-shadow: inset 0 0 0 2px $value, 0 0 0 2px $value !important;
    }
    // .CustomCheckControlControl:checked ~ .CustomCheckControlIndicator {
    //   // border-color: $value;
    // }
    // .CustomCheckControlControl:hover:not([disabled]):checked
    //   ~ .CustomCheckControlIndicator,
    // .CustomCheckControlControl:checked:focus ~ .CustomCheckControlIndicator {
    //   border-color: $value;
    //   box-shadow: 0 0 0 1px $value inset;
    // }
  }
  .checkbox.#{$key} {
    .CustomCheckControlIndicator::after {
      border-color: $value;
    }
  }
  .radio.#{$key} {
    .CustomCheckControlIndicator::after {
      background-color: $value;
    }
  }
}

.inverse {
  .CustomCheckControlIndicator {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px $color-white, 0 0 0 1px $color-white;
  }

  .CustomCheckControlControl:hover:not([disabled])
    ~ .CustomCheckControlIndicator,
  .CustomCheckControlControl:focus ~ .CustomCheckControlIndicator {
    box-shadow: inset 0 0 0 2px $color-white, 0 0 0 2px $color-white;
  }

  .CustomCheckControlControl:active ~ .CustomCheckControlIndicator,
  .CustomCheckControlControl:focus ~ .CustomCheckControlIndicator {
    box-shadow: inset 0 0 0 2px $color-white, 0 0 0 2px $color-white;
  }

  .CustomCheckControlControl:checked ~ .CustomCheckControlIndicator {
    box-shadow: inset 0 0 0 1px $color-green, 0 0 0 1px $color-green;
  }

  .CustomCheckControlControl:hover:not([disabled]):checked
    ~ .CustomCheckControlIndicator,
  .CustomCheckControlControl:checked:focus ~ .CustomCheckControlIndicator {
    box-shadow: inset 0 0 0 2px $color-green, 0 0 0 2px $color-green;
  }
}
