
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ButtonOutline {
  position: relative;
  padding: spacing(1.75) spacing(3);
  border-radius: spacing(3);
  color: $color-black;
  text-decoration: none;
  transition: all $duration-standard $easing-bounce;
  font-weight: 700;
  text-align: center;
  font-size: spacing(1.5);
  line-height: spacing(2);
  box-shadow: 0 0 0 spacing(0.25) inset $color-green, 0 0 0 0 $color-green;
  @include font-smoothing();

  @include mq($breakpoint-tablet) {
    font-size: spacing(1.75);
    line-height: spacing(2.5);
  }

  @include mq($breakpoint-desktop) {
    font-size: spacing(2);
  }

  &:hover,
  &:focus,
  &:active {
    background-color: rgba($color-primary, 0.1);
    color: $color-black;
    transition-duration: $duration-shortest;
    box-shadow: 0 0 0 spacing(0.25) inset $color-green,
      0 0 0 spacing(0.25) $color-green;
  }
}

.accent {
  @supports (--css: variables) {
    box-shadow: 0 0 0 spacing(0.25) inset
        var(--color-section-accent, $color-green),
      0 0 0 0 var(--color-section-accent, $color-green);

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      box-shadow: 0 0 0 spacing(0.25) inset
          var(--color-section-accent, $color-green),
        0 0 0 spacing(0.25) var(--color-section-accent, $color-green);
    }
  }
}

.inverse {
  color: $color-white;

  &:hover,
  &:focus,
  &:active {
    color: $color-white;
  }
}

.disabled {
  color: $color-grey50;
  border-color: $color-grey75;
}
