
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Carousel {
  display: block;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(
    to top,
    transparent spacing(4.75),
    $color-grey95 spacing(4.75)
  );

  @include mq($breakpoint-tablet) {
    background-image: linear-gradient(
      to top,
      transparent spacing(5.25),
      $color-grey95 spacing(5.25)
    );
  }

  @include mq($breakpoint-desktopMedium) {
    background-image: linear-gradient(
      to top,
      transparent spacing(6),
      $color-grey95 spacing(6)
    );
  }

  [class*='flickity-slider'] {
    > * {
      width: 100%;
    }
  }
}

.CarouselPaginationSummary {
  position: absolute;
  bottom: 0;
  right: spacing(-1);
  padding-left: spacing(2);
  background-image: linear-gradient(
    to right,
    rgba($color-white, 0),
    rgba($color-white, 1) spacing(2)
  );
  background-repeat: no-repeat;
  background-position: 0 2px;
}
