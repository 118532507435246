
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
$breakpoint-tabletSuperHero: 650px;

.SuperHeroWrapper {
  position: relative;
  margin-bottom: spacing(4);
  max-width: 2100px;
  @include mq($breakpoint-tabletSuperHero) {
    display: block;
    padding-bottom: 65%;
    margin: 0 auto spacing(7);
  }
  @include mq($breakpoint-desktop) {
    padding-bottom: 50%;
  }
  @include mq($breakpoint-desktopLarge) {
    padding-bottom: 45.55%;
  }
  @media screen and (min-width: 1545px) {
    margin-bottom: 0;
    padding-bottom: unset;
    height: 720px;
  }
}

.SuperHero {
  background-image: url('/image/SuperHeroBgSmall.jpg');
  background-repeat: no-repeat;
  background-size: 850px;
  background-position: -7vw top;
  padding: 24px 0 32px;
  @include mq($breakpoint-tabletSuperHero) {
    background-image: url('/image/SuperHeroBgMedium.jpg');
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }
  @include mq($breakpoint-desktop) {
    background-image: url('/image/SuperHeroBgLarge.jpg');
  }
  @media screen and (min-width: 1545px) {
    background-position: center bottom;
    background-size: cover;
    background-image: url('/image/SuperHeroBgXxl.jpg');
  }
}

.SuperHeroBackgroundTrim {
  position: absolute;
  bottom: -2px;
  left: -3px;
  right: -3px;
  z-index: 1000;
}

.SuperHeroContent {
  width: 100%;
  height: 100%;
  position: relative;
}

.SuperHeroMascotPosition {
  @include mq($breakpoint-tabletSuperHero) {
    position: absolute;
    left: 50%;
    bottom: 5.5vw;
    transform: translateX(-50%);
  }
  @media screen and (min-width: 1545px) {
    bottom: 85px;
  }
}

.SuperHeroMascot {
  background: url('/image/MascotBgMobile.png') top center;
  background-repeat: no-repeat;
  background-size: 340px;
  padding-top: 220px;
  @include mq($breakpoint-tabletSuperHero) {
    background: url('/image/MascotBg.png') top center;
    padding: 0;
    position: relative;
    background-size: cover;
    width: 29.19vw;
    max-width: 451px;
    padding-top: 117.517%;
  }
}

.SuperHeroLeftTop {
  transform: translate(39%, 6px);
  position: relative;
  z-index: 1;

  @include mq($breakpoint-tabletSuperHero) {
    width: 14vw;
  }
  @include mq($breakpoint-tabletLandscape) {
    width: 15vw;
  }
  @include mq($breakpoint-desktop) {
    width: 120px;
    transform: translate(39%, 6px);
  }
}

.SuperHeroRightTop {
  @include mq($breakpoint-tabletSuperHero) {
    width: 3vw;
  }
  @include mq($breakpoint-tabletLandscape) {
    width: 2.5vw;
  }
  @include mq($breakpoint-desktop) {
    width: 30px;
  }
}

.SuperHeroLeftBuoyPosition {
  @include mq($breakpoint-tabletSuperHero) {
    position: absolute;
    left: 13vw;
    bottom: 6vw;
  }
  @media screen and (min-width: 1545px) {
    left: 50%;
    bottom: 108px;
    margin-left: -527px;
  }
}

.SuperHeroRightBuoyPosition {
  @include mq($breakpoint-tabletSuperHero) {
    position: absolute;
    right: 12vw;
    bottom: 5.5vw;
  }
  @media screen and (min-width: 1545px) {
    right: 50%;
    bottom: 85px;
    margin-right: -588px;
  }
}

.SuperHeroBuoy {
  text-align: center;
  img {
    display: none;
  }

  @include mq($breakpoint-tabletSuperHero) {
    img {
      display: initial;
    }

    background-size: cover;
    transition-timing-function: ease-in-out;
    transition-duration: 1s;
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
    }
  }
}

.SuperHeroLeftBuoy {
  @include mq($breakpoint-tabletSuperHero) {
    width: 23vw;
    transform-origin: 0% 90%;
    transform: translateY(30px) rotate(-13deg);

    &:hover,
    &:focus,
    &:active {
      transform: translateY(30px) rotate(-11deg);
    }
  }
  @include mq($breakpoint-tabletLandscape) {
    width: 18vw;
  }
  @include mq($breakpoint-desktop) {
    width: 13vw;
  }
  @include mq($breakpoint-desktopLarge) {
    width: 11vw;
  }
}

.SuperHeroRightBuoy {
  @include mq($breakpoint-tabletSuperHero) {
    transform: translateY(-20px) rotate(3.5deg);
    width: 18vw;
    transform-origin: 130% 90%;
    &:hover,
    &:focus,
    &:active {
      transform: translateY(-20px) rotate(-0.5deg);
    }
  }
  @include mq($breakpoint-tabletMedium) {
    width: 17vw;
  }
  @include mq($breakpoint-tabletLandscape) {
    width: 15vw;
  }
  @include mq($breakpoint-desktop) {
    width: 12vw;
  }
}

.SuperHeroButton {
  color: $color-black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0 auto 12px;
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  @include mq($breakpoint-tabletSuperHero) {
    margin-bottom: 0;
    font-size: 0.9375rem;
    font-size: clamp(
      0.9375rem,
      calc(0.07506983240223475rem + 2.1229050279329607vw),
      2.125rem
    );
  }
}

.SuperHeroButton1 {
  width: 250px;
  height: 89px;
  background: url('/image/SuperHeroButtons.png') no-repeat;
  @include mq($breakpoint-tabletSuperHero) {
    position: absolute;
    background: none;
    left: 50%;
    transform: translateX(-45%);
    top: 3%;
    width: 17vw;
    height: 4vw;
    max-height: 61px;
    text-align: center;
  }
}

.SuperHeroButton2 {
  background: url('/image/SuperHeroButtons.png') no-repeat;
  background-position: 0 -89px;
  height: 87px;
  width: 250px;
  @include mq($breakpoint-tabletSuperHero) {
    position: absolute;
    background: none;
    left: 50%;
    transform: translateX(-45%);
    top: 66%;
    width: 14vw;
    height: 3.3vw;
    max-height: 51px;
    text-align: center;
  }
}

.SuperHeroButton3 {
  position: relative;
  border-top: 25px solid;
  border-bottom: 25px solid;
  border-left: 30px solid;
  border-right: 30px solid;
  border-image: url('/image/SuperHeroBoardLeft.svg') 25 30;
  width: 250px;
  @include mq($breakpoint-tabletSuperHero) {
    width: 115%;
    left: 50%;
    transform: translate(-50%, 0);
    display: inline-block;
    text-align: center;
    padding: 0 10px;
    position: relative;
  }

  @include mq($breakpoint-desktop) {
    margin-bottom: -5px;
    border-radius: 8px;
    width: 130%;
  }
}

.SuperHeroButton4 {
  width: 250px;
  border-top: 25px solid;
  border-bottom: 25px solid;
  border-left: 30px solid;
  border-right: 30px solid;
  border-image: url('/image/SuperHeroBoardRight.svg') 25 30;
  text-align: center;
  padding: 10px 40px;
  @include mq($breakpoint-tabletSuperHero) {
    position: relative;
    border-radius: 8px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 130%;
    padding: 0;
  }

  @include mq($breakpoint-desktop) {
    padding: 10px;
    width: 160%;
  }
}

.SuperHeroButton3,
.SuperHeroButton4 {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    inset: -2px;
    background: #fdfadb;
    z-index: 0;
  }

  span {
    position: relative;
  }
}

.bobbingAnimation {
  @include mq($breakpoint-tabletSuperHero) {
    animation-name: bobbing;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

.bobbingAnimationAlt {
  animation-duration: 4s;
  animation-delay: 1s;
}

.bobbingAnimationKiosk {
  @include mq($breakpoint-tabletSuperHero) {
    animation-name: bobbingKiosk;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    animation-delay: 1.5s;
  }
}

@keyframes bobbing {
  from {
    transform: translate(0, 0);
  }
  65% {
    transform: translate(0, 5px);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes bobbingKiosk {
  from {
    transform: translate(-50%, 0);
  }
  65% {
    transform: translate(-50%, 5px);
  }
  to {
    transform: translate(-50%, 0);
  }
}
