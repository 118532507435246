
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Tile {
  position: relative;
  display: block;
  width: spacing(8);
  flex-shrink: 0;
  @include mq($breakpoint-tablet) {
    width: spacing(11);
  }
  @include mq($breakpoint-desktop) {
    width: spacing(16);
  }
}

.TileInner {
  background-color: $color-grey85;
  @include corners;
}

.small {
  width: spacing(6);
  @include mq($breakpoint-tablet) {
    width: spacing(8);
  }
  @include mq($breakpoint-tablet) {
    width: spacing(11);
  }
}

.large {
  width: spacing(8);
  @include mq($breakpoint-tablet) {
    width: spacing(16);
  }
  @include mq($breakpoint-desktop) {
    width: spacing(22);
  }
}

.full {
  width: 100%;
}

.clickable {
  transition: all $duration-short $easing-bounce;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: box-shadow $duration-short $easing-bounce;
    box-shadow: 0 0 0 0 inset $color-green, 0 0 0 0 $color-green;
    @supports (--css: variables) {
      box-shadow: 0 0 0 0 inset var(--color-section-accent, $color-green),
        0 0 0 0 var(--color-section-accent, $color-green);
    }
    @include corners($crop: false);
  }

  &.active {
    &::after {
      box-shadow: 0 0 0 spacing(0.25) inset $color-green,
        0 0 0 spacing(0.25) $color-green;
      @supports (--css: variables) {
        box-shadow: 0 0 0 spacing(0.25) inset
            var(--color-section-accent, $color-green),
          0 0 0 spacing(0.25) var(--color-section-accent, $color-green);
      }
    }
  }

  &:hover,
  &:focus {
    outline: 0;

    &::after {
      box-shadow: 0 0 0 spacing(0.25) inset $color-green,
        0 0 0 spacing(0.25) $color-green;
      @supports (--css: variables) {
        box-shadow: 0 0 0 spacing(0.25) inset
            var(--color-section-accent, $color-green),
          0 0 0 spacing(0.25) var(--color-section-accent, $color-green);
      }
      @include mq($breakpoint-desktop) {
        box-shadow: 0 0 0 spacing(0.5) inset $color-green,
          0 0 0 spacing(0.5) $color-green;
        @supports (--css: variables) {
          box-shadow: 0 0 0 spacing(0.5) inset
              var(--color-section-accent, $color-green),
            0 0 0 spacing(0.5) var(--color-section-accent, $color-green);
        }
      }
    }
  }

  &:hover {
    transform: scale(1.05) rotate(2deg);
  }
  &:active {
    transform: scale(0.98) rotate(2deg);
    transition-duration: 0.1s;
  }

  &:nth-child(5n + 2),
  &.hover-offset-1 {
    &:hover {
      transform: scale(1.05) rotate(-2deg);
    }
    &:active {
      transform: scale(0.98) rotate(-2deg);
    }
  }

  &:nth-child(5n + 3),
  &.hover-offset-2 {
    &:hover {
      transform: scale(1.02) rotate(-1deg);
    }
    &:active {
      transform: scale(0.98) rotate(-1deg);
    }
  }

  &:nth-child(5n + 4),
  &.hover-offset-3 {
    &:hover {
      transform: scale(1.05) rotate(3deg);
    }
    &:active {
      transform: scale(0.98) rotate(3deg);
    }
  }

  &:nth-child(5n + 5),
  &.hover-offset-4 {
    &:hover {
      transform: scale(1.07) rotate(-2deg);
    }
    &:active {
      transform: scale(0.98) rotate(-2deg);
    }
  }
}
